export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ADD_TO_CART_SUCCESS = "ADD_TO_CART_SUCCESS";
export const CLEAR_FROM_CART_SUCCESS = "CLEAR_FROM_CART_SUCCESS";
export const ADD_TO_Fav_SUCCESS = "ADD_TO_Fav_SUCCESS";
export const CLEAR_FROM_Fav_SUCCESS = "CLEAR_FROM_Fav_SUCCESS";
