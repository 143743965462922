import React from "react";
import "./style.css";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
class Lessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      eshra7lyPlus: [],
      page: [],
      totalPages: [],
      CurrentPage: 0,
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  handlePageination(page) {
    this.setState({ CurrentPage: page })
    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus&page=${page}&limit=12`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          let page = response.data.meta;

          this.setState(() => ({
            eshra7lyPlus,
            page,
          }));
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus&page=${page}&limit=12`
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          let page = response.data.meta;

          this.setState({ eshra7lyPlus, page });
        });
    }

  }

  componentDidMount() {
    let headersToken = {};
    let filter = "";

    const params = new URLSearchParams(this.props.location.search);
    const year = parseInt(params.get("year"), 10) || 0;
    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          let page = response.data.meta;

          this.setState((prevState) => ({
            eshra7lyPlus,
            page,
          }));
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          let page = response.data.meta;

          this.setState({ eshra7lyPlus, page });
        });
    }

    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/check_user_subscription`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let activePackage = response.data.data;
          this.setState({ activePackage });
          if (response.data.data.code === 200) {
            this.setState({ subscriptionPackage: true });
          } else {
            this.setState({ subscriptionPackage: false });
          }
        })
        .catch((error) => {
          this.setState({ subscriptionPackage: false });
        });
    }
  }


  componentDidUpdate(prevProps, prevState, snapshot) {
    const params = new URLSearchParams(this.props.location.search);
    const year = parseInt(params.get("year"), 10) || 0;
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (this.props.isLoggedIn) {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`,
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("user")).token,
              },
            }
          )
          .then((response) => {
            let eshra7lyPlus = response.data.data;
            let page = response.data.meta;

            this.setState({ eshra7lyPlus, page });
          });
      } else {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`
          )
          .then((response) => {
            let eshra7lyPlus = response.data.data;
            let page = response.data.meta;

            this.setState({ eshra7lyPlus, page });
          });
      }
    }

    if (prevProps.location.search !== this.props.location.search) {
      if (this.props.isLoggedIn) {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`,
            {
              headers: {
                Authorization:
                  "Bearer " + JSON.parse(localStorage.getItem("user")).token,
              },
            }
          )
          .then((response) => {
            let eshra7lyPlus = response.data.data;
            let page = response.data.meta;

            this.setState((prevState) => ({
              eshra7lyPlus: [...prevState.eshra7lyPlus, ...eshra7lyPlus],
              page: page,
            }));
          });
      } else {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL +
            `/api/user/courses_list?belongs_to=eshrahly_plus&page=1&limit=12`
          )
          .then((response) => {
            let eshra7lyPlus = response.data.data;
            let page = response.data.meta;

            this.setState({ eshra7lyPlus, page });
          });
      }
    }
  }

  render() {
    var courses = this.state.eshra7lyPlus;
    var pages = this.state.page;
    var totalPages = pages.total ? Math.ceil(pages.total / 12) : 1;
    function subsribeOneSubject(course_Id) {
      let courseID = course_Id;
      localStorage.setItem("IDcourse", courseID);
      let data = {
        course_id: courseID,
      };
      axios
        .post(
          process.env.REACT_APP_API_BASE_URL + "/api/user/buy_course",
          data,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((res) => {
          if (res.data.redirect) {
            window.location.href = res.data.redirect;
          } else {
            console.log("error", res);
            // alert(`Error ${res.data}`);
          }
        });
    }
    return (
      <>
        <div className="d-inline">
          <div className="row courseCard">
            <h1>الديوان بلس</h1>
          </div>
          <div className="container mt-5">
            <div className="row ">
              {courses.map((res) => {
                return (
                  <div
                    className=" col-md-3 col-lg-3 col-sm-6 col-xs-6  mb-4 "
                    key={res.id}
                  >
                    {res.purchased ? (
                      <Link
                        to={"/moreInfoCourse/" + res.id + "/" + res.teacher?.id}
                        className="card text-decoration-none"
                      >
                        <img
                          className="card-img-top"
                          height={156}
                          src={res?.teacher?.avatar_image}
                        />
                        <div className="card-body  col cardCol">
                          <div className="card-subtitle date">
                            {" "}
                            {this.formatDate(res.academy_year?.created_at)}
                          </div>
                          <div className=" sub"> {res.name}</div>
                          <div className="card-text text-dark font-bolder ">
                            أ/{res.teacher?.name}
                          </div>
                          <div className="card-text desc"> {res.desc}</div>
                        </div>
                      </Link>
                    ) : (
                        <Link
                            to={"/moreInfoCourse/" + res.id + "/" + res.teacher?.id}
                            className="card text-decoration-none">
                        <img
                          className="card-img-top"
                          height={156}
                          src={res?.teacher?.avatar_image}
                        />
                        <div className="card-body  col cardCol">
                          <div className="card-subtitle date">
                            {" "}
                            {this.formatDate(res.academy_year?.created_at)}
                          </div>
                          <div className=" sub"> {res.name}</div>
                          <div className="card-text text-dark font-bolder ">
                            أ/{res.teacher?.name}
                          </div>
                          <div className="card-text desc"> {res.desc}</div>

                          <div className="d-flex  flex-end justify-content-between mt-3">
                            <p className=" price">{res.price} ج.م</p>
                            <p className=" old-price">{res.old_price} ج.م.</p>
                            {this.props.isLoggedIn ? (
                              <button
                                type="sumbit"
                                onClick={() => {
                                  subsribeOneSubject(res.id);
                                }}
                                className=" course-card-btn "
                              >
                                {" "}
                                اشترك الأن
                              </button>
                            ) : (
                              <Link to="/signIn" className=" course-card-btn ">
                                {" "}
                                اشترك الأن
                              </Link>
                            )}
                          </div>
                        </div>
                        </Link>
                    )}
                  </div>
                );
              })}

              <nav aria-label="Page navigation example justify-content-center">
                <ul class="pagination justify-content-center">
                  <li class="page-item red" >
                    <button class="page-link red" disabled={this.state.CurrentPage == 1} onClick={() => this.handlePageination(this.state.CurrentPage - 1)}>
                      Previous
                    </button>
                  </li>
                  {Array.from(Array(totalPages), (e, i) => {
                    return (
                      <li key={i} className="page-item red" onClick={() => this.handlePageination(i + 1)}>
                        <a className="page-link red" href="#" >
                          {i + 1}

                        </a>
                      </li>
                    );
                  })}

                  <li class="page-item red" >
                    <button class="page-link red" disabled={this.state.CurrentPage == totalPages} onClick={() => this.handlePageination(this.state.CurrentPage + 1)}>
                      Next
                    </button>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
          {/* loop though lessons here */}
          {/* <div className='section'>
            <h2></h2>
            <div className='singleCourseCard'>

            </div>
        </div> */}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  const { user, profile, isLoggedIn } = state.auth;
  return {
    user,
    profile,
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(withRouter(Lessons));
