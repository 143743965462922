import React from "react";
import Style from "style-it";
import "./style.css";
import logo from "../images/logo.PNG";
import list from "../images/list.svg";
import Search from "../images/search.svg";
import Line from "../images/nav-line.svg";
import { MdFollowTheSigns } from "react-icons/md";
import { Link, withRouter } from "react-router-dom";
import { BsBookmark, BsFillPersonFill } from "react-icons/bs";
import {
  AiFillPlayCircle,
  AiOutlineClose,
  AiOutlineYoutube,
} from "react-icons/ai";
import { IoMdSettings } from "react-icons/io";
import { VscSignIn } from "react-icons/vsc";
import { connect } from "react-redux";
import { logout } from "../actions/auth";
import axios from "axios";
import Modal from "react-bootstrap/Modal";

class Navbar extends React.Component {
  state = {
    years: [],
    name:
      JSON.parse(localStorage.getItem("profile")) &&
        JSON.parse(localStorage.getItem("profile")) !== null
        ? JSON.parse(localStorage.getItem("profile")).data?.name
        : "",
    year:
      JSON.parse(localStorage.getItem("profile")) &&
        JSON.parse(localStorage.getItem("profile")) !== null
        ? JSON.parse(localStorage.getItem("profile")).data?.academy_year?.name
        : "",
    modalShow: false,
    nameSearch: "",
    errors: [],
    search: [],
    searchQuery: "",
    searchResults: [],
    appear: false,
    eshra7lyPlus: [],
    courses: [],
    DataLinks: {},
    code:
      JSON.parse(localStorage.getItem("profile")) &&
        JSON.parse(localStorage.getItem("profile")) !== null
        ? JSON.parse(localStorage.getItem("profile")).data?.code
        : "",
  };
  x;
  logOut = () => {
    this.props.dispatch(logout());
  };

  componentDidMount() {
    let headersToken = {};
    let filter = "";

    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/api/user/setting")
      .then((r) => {
        let DataLinks = r.data.data;
        this.setState({ DataLinks });
      });
    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=special_courses`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let courses = response.data.data;
          this.setState({ courses });
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=special_courses`,
          {
            headers: {},
          }
        )
        .then((response) => {
          let courses = response.data.data;
          this.setState({ courses });
        });
    }
    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL + `/api/user/academy_years_list`,
          {
            headers: {
              Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token
                }`,
            },
          }
        )
        .then((response) => {
          let years = response.data.data;
          this.setState({ years });
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL + `/api/user/academy_years_list`
        )
        .then((response) => {
          let years = response.data.data;
          this.setState({ years });
        });
    }
    const params = new URLSearchParams(this.props.location.search);
    const year = parseInt(params.get("year"), 10) || 0;
    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          this.setState({ eshra7lyPlus });
        });
    } else {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/courses_list?belongs_to=eshrahly_plus${year != 0
            ? "&academy_year_id=" + parseInt(params.get("year"), 10)
            : "&academy_year_id=3"
          }`
        )
        .then((response) => {
          let eshra7lyPlus = response.data.data;
          this.setState({ eshra7lyPlus });
        });
    }

    if (this.props.isLoggedIn) {
      axios
        .get(
          process.env.REACT_APP_API_BASE_URL +
          `/api/user/check_user_subscription`,
          {
            headers: {
              Authorization:
                "Bearer " + JSON.parse(localStorage.getItem("user")).token,
            },
          }
        )
        .then((response) => {
          let activePackage = response.data.data;
          this.setState({ activePackage });
          if (response.data.data.code === 200) {
            this.setState({ subscriptionPackage: true });
          } else {
            this.setState({ subscriptionPackage: false });
          }
        })
        .catch((error) => {
          this.setState({ subscriptionPackage: false });
        });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.isLoggedIn !== this.props.isLoggedIn) {
      if (this.props.isLoggedIn) {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL + `/api/user/academy_years_list`,
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token
                  }`,
              },
            }
          )
          .then((response) => {
            let years = response.data.data;
            this.setState({ years });
          });
      } else {
        axios
          .get(
            process.env.REACT_APP_API_BASE_URL + `/api/user/academy_years_list`
          )
          .then((response) => {
            let years = response.data.data;
            this.setState({ years });
          });
      }
    }
  }

  followParent = () => {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + "/api/user/user_data", {
        headers: {
          Authorization:
            "Bearer " + JSON.parse(localStorage.getItem("user")).token,
        },
      })
      .then((r) => {
        if (r.data.data.code === null) {
          axios
            .post(
              process.env.REACT_APP_API_BASE_URL + `/api/user/set_user_code`,
              {},
              {
                headers: {
                  Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token
                    }`,
                },
              }
            )
            .then((response) => {
              let code = response.data.code;
              this.setState({ code }, () => {
                this.setState({ modalShow: true });
              });
            });
        } else {
          this.setState({ code: r.data.data.code, modalShow: true });
        }
      });
  };
  handleChangeGeneralInputs = (event, name) => {
    this.setState(
      { [event.target.name]: event.target.value.replace(/\s\s/g, "") },
      () => {
        let errors = {};
        let formIsValid = true;

        if (name === "nameSearch") {
          this.setState({ appear: false });
          if (!this.state.nameSearch) {
            formIsValid = false;
            errors.nameSearch = "لايمكن ان يكون فارغ";
          } else if (typeof this.state.nameSearch !== "undefined") {
            if (!this.state.nameSearch.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
              formIsValid = false;
              errors.nameSearch = "لايمكن ان يكون فارغ وحروف فقط";
            }
          } else {
            errors.nameSearch = "";
            formIsValid = true;
          }
        }
        this.setState({ errors: errors });
        return formIsValid;
      }
    );
  };
  handleValidation = () => {
    let errors = {};
    let formIsValid = true;

    if (!this.state.nameSearch) {
      formIsValid = false;
      errors.nameSearch = "لايمكن ان يكون فارغ";
    } else if (typeof this.state.nameSearch !== "undefined") {
      if (!this.state.nameSearch.match(/^[A-Za-z-أ-يa\s]+$/gi)) {
        formIsValid = false;
        errors.nameSearch = "يجب ان يكون فارغ وحروف فقط";
      }
    }

    this.setState({ errors: errors });
    return formIsValid;
  };

  confirmUpload = (e) => {
    e.preventDefault();
    if (this.handleValidation() === false) {
    } else {
      
      if (this.props.isLoggedIn) {
        this.setState({ loading: true });
        axios
          .post(
            process.env.REACT_APP_API_BASE_URL + `/api/user/search`,
            {
              keyword: this.state.nameSearch,
            },
            {
              headers: {
                Authorization: `Bearer ${JSON.parse(localStorage.getItem("user")).token
                  }`,
              },
            }
          )
          .then((response) => {
            if (response.status === 200 && this.state.nameSearch != "") {
              this.setState({ search: response.data.data, appear: true });
            }
          });
      }
    }
  };

  handleSearchQuery = (res) => {
    const searchParams = new URLSearchParams({ year: res.id });
    this.props.history.push(`?${searchParams.toString()}`);
  };

  render() {
    let sample3 = this.state.courses.slice(0, 3);

    let coursesTwo = sample3.map((res) => {
      return (
        <Style key={Math.random()}>
          <div key={res.id}>
            {!this.props.isLoggedIn ? (
              <a href="/signIn" className="text-decoration-none">
                <div className="text-truncate">{res.name}</div>
              </a>
            ) : (
              <a
                href={
                  res.purchased
                    ? "/moreInfoCourse/" + res.id + "/" + res.teacher.id
                    : "/Teachers/" + res.id + "/" + res.name
                }
                className="text-decoration-none"
                style={{color: "rgba(0,0,0,.7)", textDecoration: "none"}}
              >
                <div className="text-truncate">{res.name}</div>
              </a>
            )}
          </div>
        </Style>
      );
    });

    let sample = this.state.eshra7lyPlus.slice(0, 3);
    let singleCourse = sample.map((res) => {
      return (
        <Style key={Math.random()}>
          <div key={res.id}>
            {this.props.isLoggedIn ? (
              <a
                href={
                  res.purchased
                    ? "/moreInfoCourse/" + res.id + "/" + res.teacher.id
                    : "/Teachers/" + res.id + "/" + res.name
                }
                className="text-decoration-none"
              >
                <div className="text-truncate">{res.name}</div>
              </a>
            ) : (
              <a href="/signIn" className="text-decoration-none">
                <div className="text-truncate">{res.name}</div>
              </a>
            )}
          </div>
        </Style>
      );
    });

    let singleCourseResponsive = sample.map((res) => {
      return (
        <li
        className="dropdown-item d-flex flex-column align-items-baseline gap-2 text-decoration-none"
       key={Math.random()}>
          <div key={res.id}>
            {this.props.isLoggedIn ? (
              <a
                href={
                  res.purchased
                    ? "/moreInfoCourse/" + res.id + "/" + res.teacher.id
                    : "/Teachers/" + res.id + "/" + res.name
                }
                className="text-decoration-none"
                style={{color: "rgba(0,0,0,.7)", textDecoration: "none"}}
              >
                <div className="text-truncate">{res.name}</div>
              </a>
            ) : (
              <a href="/signIn" className="text-decoration-none">
                <div className="text-truncate">{res.name}</div>
              </a>
            )}
          </div>
        </li>
      );
    });
    let singleYear = this.state.years?.map((res) => {
      return (
        <div key={res.id}>
          <li>
            <a
              className="dropdown-item"
              href="#"
              onClick={() => this.handleSearchQuery(res)}
            >
              {res.name}
            </a>
          </li>
        </div>
      );
    });

    let sliceFour = this.state.search.slice(0, 4);
    let singleSearch = sliceFour.map((res) => {
      if (res.type === "video") {
        return (
          <div key={Math.random()} className="item1">
            <Link
              to={
                res.purchased
                  ? {
                    pathname: "/viewVideos",
                    state: { path: res.path, image: res.cover, id: res.id },
                  }
                  : "/Teachers/" + res.id + "/" + res.name
              }
              className={"noLink"}
              onClick={() => this.setState({ appear: false })}
            >
              <div className="edit-singleCourse-title">
                <div className="main-head-title-singleCourse pb-2 align-items-center">
                  <div>
                    <img style={{ borderRadius: "50%" }} src={res.cover} />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="edit-singleCourse-titleHere">
                      <p className="m-0 p-0"></p>
                      <p className="m-0 p-0">
                        <span>
                          {res.name}
                          <br /> {res.course?.name}
                        </span>
                        <br />
                        {res.teacher?.name}
                        <p>{res.teacher?.bio}</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      } else if (res.type === "teacher") {
        return (
          <div key={Math.random()} className="item1">
            <Link
              to={"/singleteacher/"}
              className={"noLink"}
              onClick={() => this.setState({ appear: false })}
            >
              <div className="edit-singleCourse-title">
                <div className="main-head-title-singleCourse pb-2 align-items-center">
                  <div>
                    <img style={{ borderRadius: "50%" }} src={res.cover} />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="edit-singleCourse-titleHere">
                      <p className="m-0 p-0"></p>
                      <p className="m-0 p-0">
                        <span>
                          {res.name}
                          <br /> {res.course?.name}
                        </span>
                        <br />
                        {res.teacher?.name}
                        <p>{res.teacher?.bio}</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      } else {
        return (
          <div key={Math.random()} className="item1">
            <Link
              to={
                res.purchased
                  ? "/moreInfoCourse/" + res.id + "/" + res.teacher[0]?.id
                  : "/Teachers/" + res.id + "/" + res.name
              }
              className={"noLink"}
              onClick={() => this.setState({ appear: false })}
            >
              <div className="edit-singleCourse-title">
                <div className="main-head-title-singleCourse pb-2 align-items-center">
                  <div>
                    <img style={{ borderRadius: "50%" }} src={res.cover} />
                  </div>
                  <div className="d-flex flex-row justify-content-between align-items-center">
                    <div className="edit-singleCourse-titleHere">
                      <p className="m-0 p-0"></p>
                      <p className="m-0 p-0">
                        <span>
                          {res.name}
                          <br /> {res.course?.name}
                        </span>
                        <br />
                        {res.teacher?.name}
                        <p>{res.teacher?.bio}</p>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        );
      }
    });
    return (
      <>
        <div>
          <nav className="navbar navbar-expand-lg navbar-light  d-none  d-lg-block ">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand">
                <img src={logo} className="nav-logo" />
              </Link>
              <Link
                to="/"
                type="button"
                data-toggle="collapse"
                className="nav-element "
              >
                {" "}
                الرئيسية
              </Link>
              {/* <div className="my-dropdown">
                <div className="my-dropbtn nav-element">الدورات التدريبية</div>
                <div className="my-dropdown-content">
                  <a className="text-truncate">{coursesTwo}</a>
                </div>
              </div>
              <div className="my-dropdown">
                <div className="my-dropbtn nav-element">الحصص الدراسية</div>
                <div className="my-dropdown-content">
                  <a>{singleCourse}</a>
                </div>
              </div> */}

              <a href="#about" className="nav-element">
                
                <div>عن الديوان</div>
              </a>
              <a
                href={`https://wa.me/${this.state.DataLinks?.whatsapp_number}`}
                target="_blank"
                className="nav-element"
              >
                {" "}
                <div>تواصل معنا</div>
              </a>

              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <div className="d-flex flex-row align-items-center">
                  {this.props.isLoggedIn &&
                    JSON.parse(localStorage.getItem("profile"))?.data
                      ?.role === "teacher" ? <></> :
                    <div className="my-dropdown">
                      <div className="my-dropdown">
                        <input
                          className="nav-search-bar "
                          type="search"
                          value={this.state.nameSearch}
                          onSelectCapture={(e) => this.confirmUpload(e)}
                          onChange={(e) =>
                            this.handleChangeGeneralInputs(e, "nameSearch")
                          }
                          name="nameSearch"
                          placeholder="ابحث عن الحصص  "
                          aria-label="Search"
                        />
                        <button className="btn " type="submit">
                          <img src={Search} className="nav-search" />
                        </button>
                        <img src={Line} width={1} height={40} />
                      </div>
                      <div
                        className="my-dropdown-content"
                        style={{ display: this.state.appear ? "block" : "none" }}
                      >
                        {singleSearch}
                      </div>
                    </div>
                  }
                  {/* <div>
                                        <ul className="navbar-nav No-Here">
                                            <li className="nav-item dropdown">
                                                <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown"
                                                   role="button"
                                                   data-bs-toggle="dropdown" aria-expanded="false">
                                                    الصف الدراسي
                                                </a>
                                                <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
                                                    {singleYear}
                                                </ul>
                                            </li>
                                        </ul>
                                    </div> */}
                  <div>
                    <form
                      className="d-flex m-auto"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <>
                        {this.props.isLoggedIn &&
                          JSON.parse(localStorage.getItem("profile"))?.data
                            ?.role === "teacher" ? (
                          <div className="add-two-button text-center ms-auto me-auto">
                            <Link to="/addVideo">
                              <button className="new-btn"> اضافه محاضرة</button>
                            </Link>
                            <Link to="/createZoom">
                              <button className="new-btn"> اضافه زووم</button>
                            </Link>
                            <Link to="/activities">
                              <button className="new-btn"> التحكم </button>
                            </Link>
                          </div>
                        ) : null}
                      </>
                    </form>
                  </div>
                </div>

                {!this.props.isLoggedIn ? (
                  <div className="add-two-button text-center me-auto">
                    <Link to="/signIn">
                      <button className="second-btn"> تسجيل دخول</button>
                    </Link>
                    <Link to="/signUp">
                      <button className="new-btn"> انشاء حساب جديد</button>
                    </Link>
                  </div>
                ) : null}
                {/*<div className='DarkMode He'><MdDarkMode/></div>*/}
              </div>
            </div>
          </nav>

          <nav className="navbar navbar-expand-lg navbar-light  d-md-block d-lg-none ">
            <div className="container-fluid">
              <Link to="/" className="navbar-brand">
                <img src={logo} className="nav-logo" />
              </Link>
              <button
                className="navbar-toggler  marginLeft  "
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon "></span>
              </button>
              <div
                className="collapse navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link to="/" className="nav-link active">
                      الرئيسية
                    </Link>
                  </li>
                  {/* <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      الحصص التعليميه
                    </a>
                    <ul
                      style={{"opacity" : "1", }}
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                    >

                        {singleCourseResponsive}
                    </ul>
                  </li>
                  <li className="nav-item dropdown">
                    <a
                      className="nav-link dropdown-toggle"
                      href="#"
                      id="navbarDropdown"
                      role="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      الدورات التدريبية
                    </a>
                    <ul
                      className="dropdown-menu"
                      aria-labelledby="navbarDropdown"
                      style={{"opacity" : "1" }}
                    >
                      <li>
                        <a
                          className="dropdown-item d-flex flex-column align-items-baseline gap-2 text-decoration-none"
                          href="#"
                        >
                          {coursesTwo}
                        </a>
                      </li>
                    </ul>
                  </li> */}
                  <li className="nav-item">
                    <a href="/about" className="nav-link">
                      عن الديوان
                    </a>
                  </li>
                  <li className="nav-item">
                    <a href="/contact" className="nav-link">
                      تواصل معنا
                    </a>
                  </li>
                  {this.props.isLoggedIn &&
                    JSON.parse(localStorage.getItem("profile"))?.data
                      ?.role === "teacher" ? (
                    <div className="">
                      <li className="nav-item mb-2">
                        <a href="/addVideo">
                          <button className="new-btn"> اضافه محاضرة</button>
                        </a>
                      </li>
                      <li className="nav-item mb-2">
                        <a href="/createZoom">
                          <button className="new-btn"> اضافه زووم</button>
                        </a>
                      </li>
                      <li className="nav-item mb-2">
                        <a href="/activities">
                          <button className="new-btn"> التحكم </button>
                        </a>
                      </li>
                    </div>
                  ) : null}
                  {this.props.isLoggedIn ? (
                    <>
                      <li className="nav-item">
                        <div className=" text-truncate ">
                          {" "}
                          {JSON.parse(localStorage.getItem("profile"))
                            ? JSON.parse(localStorage.getItem("profile")).data
                              ?.name
                            : ""}{" "}
                        </div>
                        <i
                          title="logout"
                          style={{ margin: "10px", cursor: "pointer" }}
                          onClick={this.logOut}
                          className="fa-solid fa-right-from-bracket mr-4"
                        ></i>
                      </li>
                    </>
                  ) : (
                    <div className="add-two-button text-center ms-auto me-auto">
                      <Link to="/signIn">
                        <button className="second-btn"> تسجيل دخول</button>
                      </Link>
                      <Link to="/signUp">
                        <button className="new-btn"> انشاء حساب جديد</button>
                      </Link>
                    </div>
                  )}
                </ul>
              </div>
            </div>
          </nav>

          {this.props.isLoggedIn ? (
            <>
              <div className="edit-here-navbarr ">
                <div className=" text-truncate ">
                  {" "}
                  {JSON.parse(localStorage.getItem("profile"))
                    ? JSON.parse(localStorage.getItem("profile")).data?.name
                    : ""}{" "}
                </div>
                <i
                  title="logout"
                  style={{ margin: "10px", cursor: "pointer" }}
                  onClick={this.logOut}
                  className="fa-solid fa-right-from-bracket mr-4"
                ></i>
              </div>
            </>
          ) : null}
        </div>
        <Modal
          show={this.state.modalShow}
          onHide={() => this.setState({ modalShow: false })}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body>
            <div
              className="modal-dialog modal-dialog-centered modal-lg"
              role="document"
            >
              <div className="product_one_modal_top modal-content">
                <button
                  type="button"
                  className="close close_modal_icon"
                  onClick={() => this.setState({ modalShow: false })}
                >
                  <span aria-hidden="true">
                    <AiOutlineClose />
                  </span>
                </button>
                <div className="colorBlack fontSize20 text-center mt-2">
                  يمكنك ارسال الكود الى ولى امرك فيستطيع من خلاله إدخاله عند
                  تسجيل الدخول كولى امر
                </div>
                <div className="colorBlack fontSize-30 text-center mt-4">
                  الكود الخاص بحسابك
                </div>
                <div className="colorBlack fontSize-30 text-center mt-4">
                  {this.state.code}
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

function mapStateToProps(state) {
  const { message } = state.message;
  const { user, profile, isLoggedIn } = state.auth;
  return {
    user,
    profile,
    isLoggedIn,
    message,
  };
}

export default connect(mapStateToProps)(withRouter(Navbar));
