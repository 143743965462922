import {LOGIN_FAIL, LOGIN_SUCCESS, LOGOUT, REGISTER_FAIL, REGISTER_SUCCESS} from "../actions/types";

const user = JSON.parse(localStorage.getItem("user"));
const profile = JSON.parse(localStorage.getItem("profile"));
const cartItem = JSON.parse(localStorage.getItem("cartItem"));
const favourite = JSON.parse(localStorage.getItem("favourite"));


const initialState = user
    ? {isLoggedIn: true, user, profile, cart: cartItem, favourite: favourite}
    : {isLoggedIn: false, user: null, profile: null, cart: null, favourite: null};

export default function (state = initialState, action) {
    const {type, payload} = action;

    switch (type) {
        case REGISTER_SUCCESS:
            return {
                ...state,
                isLoggedIn: false,
            };
        case REGISTER_FAIL:
            return {
                ...state,
                isLoggedIn: false,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                isLoggedIn: true,
                user: payload.user,
                profile: payload.profile,
                cart: payload.cart,
                favourite: payload.favourite

            };

        case LOGIN_FAIL:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                profile: null,
                cart: null,
                favourite: null
            };
        case LOGOUT:
            return {
                ...state,
                isLoggedIn: false,
                user: null,
                cart: null,
                favourite: null
            };
        default:
            return state;
    }
}
